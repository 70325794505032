import { container } from '@k-mille/ui-components'
import Component from './info'

const mapStore = store => ({
  ...store.ui.invoice.get().client,
})

export default container({
  mapStore,
})(Component)
