export default {
  main: {
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
}
