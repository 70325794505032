import { container } from '@k-mille/ui-components'
import Component from './line'

const intl = 'number'

const mapStore = (store, { id, total }, { form, ui }) => {
  const { count, pricePerUnit } = form(id).get()
  return {
    formName: id,
    isEdit: ui.invoice.isEdit(),
    value: id ? count * pricePerUnit : total,
  }
}

export default container({
  mapStore,
  intl,
})(Component)
