import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './invoice.styles'
import Project from './project'
import Client from './client'
import Dates from './dates'
import Title from './title'
import Services from './services'
import Iban from './iban'
import Cgv from './cgv'
import Edit from './edit'
import EditWarning from './editWarning'

const Invoice = ({
  classes,
  project,
  dates,
}) => (
  <div className={classes.main}>
    <div className={classes.header}>
      <Project />
      <Client />
    </div>

    <Title />

    <Dates
      className={classes.dates}
      {...dates}
    />

    <Services />

    <div className={classes.footer}>
      {project.iban && (
        <Iban />
      )}
      {project.isAssociation || (
        <Cgv />
      )}
    </div>
    <Edit />
    <EditWarning />
  </div>
)

Invoice.propTypes = {
  classes: PropTypes.object.isRequired,
  dates: PropTypes.object,
  project: PropTypes.shape({
    isAssociation: PropTypes.bool,
    iban: PropTypes.string,
    bic: PropTypes.string,
  }),
}

Invoice.defaultProps = {
  dates: {},
  project: {},
}

export default component({ styles })(Invoice)
