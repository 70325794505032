import { container } from '@k-mille/ui-components'
import Component from './project'

const intl = 'project'

const mapStore = store => store.ui.invoice.get().project

export default container({
  mapStore,
  intl,
})(Component)
