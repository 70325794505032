import { container } from '@k-mille/ui-components'
import Component from './invoices'
import listeners from './invoices.listeners'

const name = 'main'

const mapStore = store => ({
  invoices: store.data.invoices.getKeys(),
})

export default container({
  name,
  isScreen: true,
  listeners,
  mapStore,
})(Component)
