import format from 'date-fns/format'
import {
  projects,
  clients,
  invoices,
  addInvoice,
  replaceInvoice,
} from './invoice.operations'

export const load = (action, store, { router, graphql, form }) => {
  const id = router.getParam('id')
  // reset old invoice
  store.ui.invoice.set({
    dates: {
      print: Date.now(),
    },
  })
  store.ui.form.reset()
  // init empty invoice
  form('new').set({ 'dates.end': format(Date.now(), 'yyyy-MM-dd') })
  form('line-0').set({
    title: '',
    count: 0,
    pricePerUnit: 0,
  })

  graphql.query(`
    ${projects()}
    ${clients()}
    ${id !== 'new' ? invoices(id) : ''}
  `)
}

export const setFr = (action, store) => {
  store.i18n.lang.set('fr')
}

export const setDefaultLang = (action, store, { i18n }) => {
  store.i18n.lang.set(i18n.getBrowserLang())
}

const mapInvoice = invoice => ({
  title: String(invoice.number).padStart(5, '0'),
  ...invoice,
})

export const setInvoice = ({ payload }, store) => {
  if (payload.length === 0) return
  store.data.invoices.add(mapInvoice(payload[0]))
}

export const setEditInvoice = ({ payload: invoice }, store, { form }) => {
  store.ui.form.reset()
  store.ui.invoice.set(mapInvoice(invoice))
  form('new').set({ 'dates.end': format(invoice.dates.end, 'yyyy-MM-dd') })
  invoice.lines.forEach(({ total, ...line }, index) => form(`line-${index}`).set(line))
}

export const setClients = ({ payload }, store) => {
  store.data.clients.add(payload)
}

const mapProject = project => ({
  ...project,
  isAssociation: project.type.toLowerCase().includes('association'),
})

export const setProject = ({ payload }, store, { router }) => {
  const connectedProject = store.data.profile.project.get()
  const project = payload.find(({ id }) => id === connectedProject.code)

  if (!project) {
    console.error('Project not found')
    console.error('\tprojects retrieved', payload.map(({ id }) => id))
    console.error('\tconnected project', connectedProject.id)
    router.push('login')
    return
  }

  store.ui.invoice.update({
    project: mapProject(project),
  })
}

export const updateTotals = (action, store, { form }) => {
  const { project } = store.ui.invoice.get()

  const lines = form.find(/line-.+/).map(id => form(id).get())

  const prices = {
    taxesRatio: project && (project.isAssociation || project.withoutVat) ? 0 : 0.2,
    total: 0,
    totalWithoutTaxes: 0,
    taxes: 0,
  }
  prices.totalWithoutTaxes = lines.reduce(
    (total, { count = 0, pricePerUnit = 0 }) => total + (count * pricePerUnit),
    0,
  )
  prices.taxes = prices.taxesRatio * prices.totalWithoutTaxes
  prices.total = prices.taxes + prices.totalWithoutTaxes

  store.ui.invoice.update({ prices })
}

export const setType = (action, store) => {
  const { project } = store.ui.invoice.get()
  if (!project) return

  store.ui.invoice.update({
    project: mapProject(project),
  })
}

export const save = (action, store, drivers) => {
  const { graphql, form, ui } = drivers
  const invoice = store.ui.invoice.get()
  const lines = form.find(/line-.+/).map(id => form(id).get())
  const othersInput = form('new').get()
  const { id } = invoice

  graphql.mutation(id && ui.invoice.isEdit() ? replaceInvoice() : addInvoice(), {
    invoice: {
      id,
      client: {
        id: invoice.client.id,
      },
      dates: {
        end: (new Date(othersInput['dates.end'] || Date.now())).getTime(),
      },
      lines,
    },
  })
}

export const addAndRedirect = ({ payload }, store, { router }) => {
  const invoice = mapInvoice(payload)
  store.data.invoices.update(invoice)
  store.ui.invoice.set(invoice)
  router.replace('invoice', { id: payload.id })
}

export const updateClientToInvoice = ({ payload }, store) => {
  const firstClientId = typeof payload === 'string' ? payload : payload[0].id
  const client = store.data.clients.get(firstClientId)

  store.ui.invoice.update({ client })
}

export const addLine = (action, store, { form }) => {
  const invoice = store.ui.invoice.get()
  if (!invoice) return

  form(`line-${Date.now()}`).set({
    title: '',
    count: 0,
    pricePerUnit: 0,
  })
}

export const removeLine = ({ payload }, store, { form }) => {
  form(payload.id).reset()
  const lines = form.find(/line-.+/)

  // add a default line if invoice becomes empty
  if (lines.length === 0) {
    addLine(undefined, store, { form })
  }
}

export const openEditModal = ({ payload }, store, { ui }) => {
  if (
    (payload && payload.name === 'EDIT_INVOICE')
    || ui.invoice.isEdit()
  ) {
    store.ui.modalId.set('EDIT_WARNING')
  }
}

export const confirmEdit = (action, store, { router }) => {
  store.ui.modalId.reset()
  router.replace('invoice', { id: router.getParam('id') }, { edit: true })
}

export const cancelEdit = (action, store, { router, ui }) => {
  store.ui.modalId.reset()
  if (ui.invoice.isEdit()) {
    const id = router.getParam('id')
    router.replace('invoice', { id })
  }
}
