import { router } from '@k-redux-router/react-k-ramel'

const routes = {
  '/': {
    code: 'main',

    applicationName: 'Invoices',
    public: false,

    '/login': {
      code: 'login',
    },
    '/invoices/:id': {
      code: 'invoice',
    },
  },
}

export default router({ routes })
