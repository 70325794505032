export default {
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontSize: '.9em',
    marginTop: '2em',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  bold: {
    fontWeight: 'bold',
    marginLeft: '.3em',
  },
}
