import { container } from '@k-mille/ui-components'
import Component from './edit'

const intl = 'actions'

const mapStore = (store, ownProps, { ui }) => ({
  isEdit: ui.invoice.isEdit(),
  ...store.ui.invoice.get(),
})

export default container({
  mapStore,
  intl,
})(Component)
