import { when } from 'k-ramel'
import {
  load,
  setInvoices,
  paid,
  refreshPaid,
} from './invoices.reactions'

export default [
  when('@@krml/LISTENERS>ADDED>main')(load),
  when('@@graphql/invoices>ENDED')(setInvoices),
  when('@@ui/BUTTON>invoice_paid')(paid),
  when('@@graphql/paidInvoice>ENDED')(refreshPaid),
]
