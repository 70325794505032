export default {
  main: {
    borderCollapse: 'collapse',
    borderSpacing: '1px',
    width: '100%',

    '& th, & td': {
      height: '2em',
      padding: '0 1em',
      textAlign: 'center',
      border: 'solid 1px #333',
    },

    '& th:nth-child(1), & td:nth-child(1)': {
      textAlign: 'left',
      width: '70%',
    },
  },

  header: {
    backgroundColor: '#989898',
    color: 'white',
    fontWeight: 'bold',
  },
}
