import React from 'react'
import PropTypes from 'prop-types'
import { component, Input } from '@k-mille/ui-components'
import styles from './dates.styles'

const Dates = ({
  classes,
  messages,
  print,
  end,
  isEdit,
  isAssociation,
}) => (
  <div className={classes.main}>
    <div>
      {messages.printed}
      <span className={classes.bold}>{print && messages.printedValue}</span>
    </div>
    {isAssociation || (
      <div>
        {messages.ended}
        {isEdit
          ? <Input formName="new" name="dates.end" type="date" />
          : <span className={classes.bold}>{end && messages.endedValue}</span>
        }
      </div>
    )}
  </div>
)

Dates.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  print: PropTypes.number,
  end: PropTypes.number,
  isEdit: PropTypes.bool,
  isAssociation: PropTypes.bool,
}

Dates.defaultProps = {
  print: undefined,
  end: undefined,
  isEdit: false,
  isAssociation: false,
}

export default component({ styles })(Dates)
