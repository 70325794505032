export default {
  main: {
  },
  title: {
    marginTop: '1em',
    fontWeight: 'bold',
  },

  logo: {
    maxWidth: '10em',
    maxHeight: '5em',
  },
}
