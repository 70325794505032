export default theme => ({
  main: {
    marginTop: '2em',

    '& table': {
      borderCollapse: 'collapse',
      borderSpacing: '1px',
      width: '100%',
    },

    '& th, & td': {
      height: '2em',
      padding: '0 1em',
      border: 'solid 1px #333',
    },

    '& tr > td': {
      whiteSpace: 'nowrap',
    },
  },

  remove: {
    maxWidth: '10em',
  },

  header: {
    background: ({ project = {} }) => (project.parameters && project.parameters.primaryColor) || theme.primary,
    color: 'white',
  },

  title: {
    textAlign: 'left',
    width: '70%',
  },

  number: {
    textAlign: 'center',
  },

  price: {
    textAlign: 'center',
  },

  note: {
    marginTop: '2em',
  },

  bold: {
    fontWeight: 'bold',
  },
})
