import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './cgv.styles'

const Cgv = ({
  classes,
  messages,
}) => (
  <div className={classes.main}>
    {messages.title}
    <ul>
      <li>
        {messages.description}
      </li>
    </ul>
  </div>
)

Cgv.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
}

export default component({ styles })(Cgv)
