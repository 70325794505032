import { container } from '@k-mille/ui-components'
import Component from './priceRow'

const intl = 'number'

const mapStore = (store, ownProps, { ui }) => {
  const { project } = store.ui.invoice.get()

  return {
    isEdit: ui.invoice.isEdit(),
    project,
  }
}

export default container({
  mapStore,
  intl,
})(Component)
