import { container } from '@k-mille/ui-components'
import Component from './invoice'

const intl = 'invoice'

const mapStore = (store, { id }, { router }) => {
  const invoice = store.data.invoices.get(id)
  return {
    ...invoice,
    ...invoice.dates,
    total: invoice.prices,
    onClick: () => {
      router.push('invoice', { id })
    },
  }
}

export default container({
  intl,
  mapStore,
})(Component)
