import { container } from '@k-mille/ui-components'
import Component from './iban'

const intl = 'iban'

const mapStore = (store) => {
  if (!store.ui.invoice.isInitialized()) return {}
  return store.ui.invoice.get().project
}

export default container({
  mapStore,
  intl,
})(Component)
