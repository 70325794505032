import React from 'react'
import PropTypes from 'prop-types'
import { component, List, Icon } from '@k-mille/ui-components'
import InvoiceActions from './actions'
import styles from './invoice.styles'

const Invoice = ({
  classes,
  messages,
  id,
  client,
  isPaid,
  onClick,
}) => (
  <List.Item
    className={classes.main}
    onClick={onClick}
  >
    <div className={classes.since}>
      <Icon>{isPaid ? 'done' : 'watch_later'}</Icon>
      <div>{messages.printedDate}</div>
    </div>
    <div className={classes.title}>
      {messages.title}
    </div>
    <div className={classes.info}>
      <div className={classes.client}>
        {client.name}
      </div>
      <div className={classes.separator} />
      <div className={classes.price}>
        {messages.total}
      </div>
    </div>
    <InvoiceActions id={id} />
  </List.Item>
)

Invoice.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  client: PropTypes.shape({ name: PropTypes.string }),
  isPaid: PropTypes.bool,
  onClick: PropTypes.func,
}

Invoice.defaultProps = {
  client: {},
  isPaid: false,
  onClick: undefined,
}

export default component({ styles })(Invoice)
