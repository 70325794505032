import { container } from '@k-mille/ui-components'
import Component from './client'

const intl = 'client'

const mapStore = (store, ownProps, { ui }) => ({
  isEdit: ui.invoice.isEdit(),
})

export default container({
  mapStore,
  intl,
})(Component)
