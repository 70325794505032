export default {
  main: {
  },
  input: {
    display: 'inline',

    '& > input': {
      maxWidth: ({ type }) => (type === 'number' ? '5em' : 'inherit'),
      minWidth: ({ type }) => (type === 'number' ? 'inherit' : '90%'),
    },
  },
}
