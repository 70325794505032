import { container } from '@k-mille/ui-components'
import Component from './services'

const intl = 'services'

const mapStore = (store, ownProps, { ui, form }) => {
  const isEdit = ui.invoice.isEdit()
  const invoice = store.ui.invoice.get()

  let { lines } = invoice
  if (isEdit) lines = form.find(/line-.+/).map(id => ({ id }))

  return {
    ...invoice,
    total: invoice.prices && invoice.prices.total,
    isEdit: ui.invoice.isEdit(),
    lines,
  }
}

export default container({
  mapStore,
  intl,
})(Component)
