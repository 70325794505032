import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './iban.styles'

const Iban = ({
  classes,
  messages,
  iban,
  bic,
}) => (
  <table className={classes.main}>
    <tbody>
      {/* header of table */}
      <tr className={classes.header}>
        <td>{messages.iban}</td>
        <td>{messages.bic}</td>
      </tr>

      <tr>
        <td>{iban}</td>
        <td>{bic}</td>
      </tr>
    </tbody>
  </table>
)

Iban.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  iban: PropTypes.string,
  bic: PropTypes.string,
}

Iban.defaultProps = {
  iban: undefined,
  bic: undefined,
}

export default component({ styles })(Iban)
