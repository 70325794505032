import React from 'react'
import PropTypes from 'prop-types'
import { component, Input } from '@k-mille/ui-components'
import styles from './input.styles'

const InputLine = ({
  classes,
  messages,
  name,
  formName,
  type,
  value,
  readOnly,
  isPrice,
}) => (
  <div className={classes.main}>
    {readOnly && isPrice && messages.price}
    {readOnly && !isPrice && value}
    {!readOnly && (
      <Input
        className={classes.input}
        type={type}
        formName={formName}
        name={name}
        filled
      />
    )}
  </div>
)

InputLine.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  name: PropTypes.string,
  formName: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  readOnly: PropTypes.bool,
  isPrice: PropTypes.bool,
}

InputLine.defaultProps = {
  name: undefined,
  formName: undefined,
  type: 'text',
  value: '',
  readOnly: false,
  isPrice: false,
}

export default component({ styles })(InputLine)
