import React from 'react'
import PropTypes from 'prop-types'
import { component, Modal, Button } from '@k-mille/ui-components'
import styles from './editWarning.styles'


const EditWarning = ({
  classes,
  messages,
}) => (
  <Modal
    className={classes.main}
    id="EDIT_WARNING"
    closeOutside={false}
  >
    <Modal.Header>
      {messages.title}
    </Modal.Header>
    <Modal.Content>
      {messages.content}
    </Modal.Content>
    <Modal.Actions>
      <Button
        primary
        name="EDIT_CONFIRM"
      >
        {messages.confirm}
      </Button>
      <Button
        secondary
        name="EDIT_CANCEL"
      >
        {messages.cancel}
      </Button>
    </Modal.Actions>
  </Modal>
)

EditWarning.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
}

export default component({ styles })(EditWarning)
