import { container } from '@k-mille/ui-components'
import Component from './title'

const intl = 'invoice'

const mapStore = (store, ownProps, { ui }) => ({
  ...store.ui.invoice.get(),
  isEdit: ui.invoice.isEdit(),
})

export default container({
  mapStore,
  intl,
})(Component)
