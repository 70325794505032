export default {
  main: {
    justifyContent: 'center',
    backgroundColor: ({ isPaid }) => (isPaid ? '#66bb6a' : 'white'),
    border: 'dashed 1px #ccc',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '40em',
    cursor: 'pointer',
  },
}
