import React from 'react'
import PropTypes from 'prop-types'
import { component, List, Icon } from '@k-mille/ui-components'
import cn from 'classnames'
import styles from './add.styles'

const Add = ({
  classes,
  onClick,
}) => (
  <List.Item
    className={cn('add-invoice', classes.main)}
    onClick={onClick}
  >
    <Icon>add</Icon>
  </List.Item>
)

Add.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

Add.defaultProps = {
  onClick: undefined,
}

export default component({ styles })(Add)
