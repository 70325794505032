import { container } from '@k-mille/ui-components'
import listeners from './invoice.listeners'
import Component from './invoice'
import LoadingIndicator from './invoice.loading'

const name = 'invoice'

const mapStore = store => store.ui.invoice.get()

export default container({
  name,
  isScreen: true,
  mapStore,
  listeners,
  loader: 'project',
  LoadingIndicator,
})(Component)
