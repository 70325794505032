export default theme => ({
  main: {
    '& > td': {
      textAlign: 'right',
      color: ({ ttc }) => (ttc ? 'white' : 'inherit'),
      fontWeight: ({ ttc }) => (ttc ? 'bold' : 'inherit'),
      backgroundColor: ({ ttc, project = {} }) => (ttc ? (project.parameters && project.parameters.secondaryColor) || theme.secondary : 'inherit'),
    },
  },

  blank: {
    border: 'none !important',
    backgroundColor: 'white !important',
  },
})
