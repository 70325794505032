/**
 * QUERY
 */
export const invoices = () => `
  invoices {
    id
    number
    isPaid
    file
    client {
      id
      name
    }
    prices {
      total
    }
    dates {
      print
    }
  }
`

export const paidInvoice = () => `
  PaidInvoice ($id: String!) {
    paidInvoice(input: $id) {
      id
      isPaid
    }
  }
`
