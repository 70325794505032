import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './edit.styles'

const ClientEdit = ({
  classes,
  clients,
  onChange,
}) => (
  <select className={classes.main} onChange={onChange}>
    {Object.entries(clients).map(([id, client]) => (
      <option
        key={id}
        value={id}
      >
        {client.name}
      </option>
    ))}
  </select>
)

ClientEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  clients: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  onChange: PropTypes.func,
}

ClientEdit.defaultProps = {
  clients: {},
  onChange: undefined,
}

export default component({ styles })(ClientEdit)
