import React from 'react'
import PropTypes from 'prop-types'
import { component, List } from '@k-mille/ui-components'
import Add from './add'
import Invoice from './invoice'
import styles from './invoices.styles'

const Invoices = ({
  classes,
  invoices,
}) => (
  <List className={classes.main}>
    <Add />
    {invoices.map(id => <Invoice key={id} id={id} />)}
  </List>
)

Invoices.propTypes = {
  classes: PropTypes.object.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.string),
}

Invoices.defaultProps = {
  invoices: [],
}

export default component({ styles })(Invoices)
