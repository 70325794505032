import { when } from 'k-ramel'
import {
  load,
  setFr,
  setDefaultLang,
  setInvoice,
  setEditInvoice,
  setClients,
  setProject,
  updateTotals,
  setType,
  save,
  addAndRedirect,
  updateClientToInvoice,
  addLine,
  removeLine,
  openEditModal,
  confirmEdit,
  cancelEdit,
} from './invoice.reactions'

export default [
  when('@@krml/LISTENERS>ADDED>invoice')(load),
  when('@@krml/LISTENERS>ADDED>invoice')(setFr),
  when('@@krml/LISTENERS>REMOVING>invoice')(setDefaultLang),

  when('@@graphql/invoices>ENDED')(setInvoice),
  when('@@krf/ADD>DATA>INVOICES')(setEditInvoice),
  when('@@graphql/invoices>FAILED')(setEditInvoice),
  when('@@graphql/clients>ENDED')(setClients),
  when('@@graphql/projects>ENDED')(setProject),
  when(/@@krf\/(ADD|UPDATE)>UI_FORM>VALUES/, ({ payload }) => payload['@@form-name'].startsWith('line-'))(updateTotals),
  when('@@krf/REMOVE>UI_FORM>VALUES', ({ payload }) => payload.startsWith('line-'))(updateTotals),
  when('@@krf/SET>UI>INVOICE')(setType),

  when('@@ui/BUTTON>SAVE_INVOICE')(save),

  when('@@graphql/addInvoice>ENDED')(addAndRedirect),
  when('@@graphql/replaceInvoice>ENDED')(addAndRedirect),

  when('@@ui/BUTTON>ADD_LINE')(addLine),
  when('@@ui/BUTTON>REMOVE_LINE')(removeLine),

  when('@@ui/ON_CHANGE>CLIENTS')(updateClientToInvoice),
  when('@@krf/ADD>DATA>CLIENTS')(updateClientToInvoice),

  when('@@krf/ADD>DATA>INVOICES')(openEditModal),
  when('@@ui/BUTTON>EDIT_INVOICE')(openEditModal),
  when('@@ui/BUTTON>EDIT_CONFIRM')(confirmEdit),
  when('@@ui/BUTTON>EDIT_CANCEL')(cancelEdit),
]
