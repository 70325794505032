import React from 'react'
import PropTypes from 'prop-types'
import { component, Button } from '@k-mille/ui-components'
import styles from './edit.styles'

const InvoiceEdit = ({
  classes,
  messages,
  isEdit,
  isPaid,
}) => (
  <div className={classes.main}>
    {isEdit && (
      <Button
        primary
        name="SAVE_INVOICE"
      >
        {messages.save}
      </Button>
    )}
    {!isEdit && !isPaid && (
      <Button
        name="EDIT_INVOICE"
      >
        {messages.edit}
      </Button>
    )}
  </div>
)

InvoiceEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  isPaid: PropTypes.bool,
}

InvoiceEdit.defaultProps = {
  isEdit: false,
  isPaid: false,
}

export default component({ styles })(InvoiceEdit)
