import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './priceRow.styles'

const PriceRow = ({
  classes,
  messages,
  title,
  type,
  isEdit,
}) => (
  <tr className={classes.main}>
    <td className={classes.blank} colSpan={isEdit ? 3 : 2} />
    <td>{title}</td>
    <td>{type === 'price' ? messages.price : messages.percent}</td>
  </tr>
)

PriceRow.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  project: PropTypes.shape({
    parameters: PropTypes.shape({
      secondaryColor: PropTypes.string,
    }),
  }),
  isEdit: PropTypes.bool,
}

PriceRow.defaultProps = {
  title: undefined,
  type: 'price',
  project: {},
  isEdit: false,
}

export default component({ styles })(PriceRow)
