import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './title.styles'


const Title = ({
  classes,
  messages,
  isEdit,
}) => (
  <div className={classes.main}>
    <div className={classes.title}>{messages.invoice}</div>
    <span className={classes.number}>
      {isEdit ? messages.draft : messages.title }
    </span>
  </div>
)

Title.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
}

Title.defaultProps = {
  isEdit: false,
}

export default component({ styles })(Title)
