import { differenceInMonths } from 'date-fns'

const getPaidColor = theme => ({ isPaid, dates }) => {
  if (isPaid) return theme.success
  const difference = differenceInMonths(Date.now(), dates.print)
  if (difference > 6) return theme.error
  if (difference > 2) return theme.warning
  return theme.lightGrey
}
const getPaidWeight = ({ isPaid }) => (isPaid ? 'normal' : 'bold')

export default theme => ({
  main: {
    cursor: 'pointer',
    justifyContent: 'space-between',
    background: ({ isPaid }) => (isPaid ? theme.listBackgroundDark : theme.listBackground),
  },

  title: {
    fontWeight: getPaidWeight,
  },

  price: {
    color: theme.grey,
  },

  client: {
    fontWeight: getPaidWeight,
  },

  since: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '.5em',
    width: '6em',
    color: getPaidColor(theme),
    '& > i': {
      color: getPaidColor(theme),
      marginRight: '.5em',
    },
  },

  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: '2em',
  },

  separator: {
    borderLeft: `solid 1px ${theme.listBorder}`,
    height: '1.5em',
    margin: '0 .5em',
  },
})
