import { container } from '@k-mille/ui-components'
import Component from './dates'

const intl = 'date'

const mapStore = (store, { id }, { ui }) => {
  const { project } = store.ui.invoice.get()

  return {
    formName: id,
    isEdit: ui.invoice.isEdit(),
    isAssociation: project && project.isAssociation,
  }
}

export default container({
  mapStore,
  intl,
})(Component)
