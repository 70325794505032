import React from 'react'
import PropTypes from 'prop-types'
import { component, Button } from '@k-mille/ui-components'
import styles from './line.styles'
import Input from './input'

const ServicesLine = ({
  classes,
  messages,
  formName,
  title,
  count,
  pricePerUnit,
  isEdit,
}) => (
  <tr className={classes.main}>
    {isEdit && (
      <td>
        <Button small name="REMOVE_LINE" id={formName}>
          X
        </Button>
      </td>
    )}
    <td>
      <Input
        formName={formName}
        name="title"
        value={title}
        readOnly={!isEdit}
      />
    </td>
    <td className={classes.center}>
      <Input
        type="number"
        formName={formName}
        name="count"
        value={count}
        readOnly={!isEdit}
      />
    </td>
    <td className={classes.right}>
      <Input
        type="number"
        formName={formName}
        name="pricePerUnit"
        value={pricePerUnit}
        readOnly={!isEdit}
        isPrice
      />
    </td>
    <td className={classes.right}>
      {messages.price}
    </td>
  </tr>
)

ServicesLine.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  formName: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  pricePerUnit: PropTypes.number,
  isEdit: PropTypes.bool,
}

ServicesLine.defaultProps = {
  formName: undefined,
  title: undefined,
  count: 0,
  pricePerUnit: 0,
  isEdit: false,
}

export default component({ styles })(ServicesLine)
