import { container } from '@k-mille/ui-components'
import Component from './edit'

const mapStore = store => ({
  clients: store.data.clients.get(),
  onChange: e => store.dispatch({ type: '@@ui/ON_CHANGE>CLIENTS', payload: e.target.value }),
})

export default container({
  mapStore,
})(Component)
