import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './invoice.styles'

const InvoiceLoading = ({
  classes,
}) => (
  <div className={classes.main} />
)

InvoiceLoading.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default component({ styles })(InvoiceLoading)
