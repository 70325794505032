import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './project.styles'

const Project = ({
  classes,
  messages,
  name,
  type,
  siren,
  intraCommunityTvaNumber,
  capital,
  address,
  city,
  postalCode,
  logo,
}) => (
  <div className={classes.main}>
    {logo && (
      <img
        className={classes.logo}
        src={logo}
        alt={name}
      />
    )}

    <div className={classes.title}>{messages.title}</div>
    <div>{name}</div>
    {!!siren && (
      <div>{messages.siren}</div>
    )}
    {!!intraCommunityTvaNumber && (
      <div>{messages.intraCommunityVATNumber}</div>
    )}
    {!!capital && !!type && (
      <div>{messages.typeWithCapital}</div>
    )}
    <div className={classes.title}>Adresse</div>
    <div>{address}</div>
    <div>{`${postalCode} ${city}`}</div>
  </div>
)

Project.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  siren: PropTypes.string,
  intraCommunityTvaNumber: PropTypes.string,
  capital: PropTypes.number,
  address: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  logo: PropTypes.string,
}

Project.defaultProps = {
  name: undefined,
  type: undefined,
  siren: undefined,
  intraCommunityTvaNumber: undefined,
  capital: undefined,
  address: undefined,
  city: undefined,
  postalCode: undefined,
  logo: undefined,
}

export default component({ styles })(Project)
