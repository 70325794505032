import { container } from '@k-mille/ui-components'
import Component from './editWarning'

const intl = 'editWarning'

const mapStore = store => store.ui.invoice.get()

export default container({
  mapStore,
  intl,
})(Component)
