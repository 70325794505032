import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './info.styles'

const ClientInfo = ({
  classes,
  name,
  address,
  city,
  postalCode,
}) => (
  <div className={classes.main}>
    <div>{name}</div>
    <div>{address}</div>
    <div>{`${postalCode} ${city}`}</div>
  </div>
)

ClientInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
}

ClientInfo.defaultProps = {
  name: '',
  address: '',
  city: '',
  postalCode: '',
}

export default component({ styles })(ClientInfo)
