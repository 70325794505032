import { container } from '@k-mille/ui-components'
import Component from './add'

const mapStore = (store, ownProps, { router }) => ({
  onClick: () => {
    router.push('invoice', { id: 'new' }, { edit: true })
  },
})

export default container({
  mapStore,
})(Component)
