import React from 'react'
import PropTypes from 'prop-types'
import { component } from '@k-mille/ui-components'
import styles from './client.styles'
import ClientInfo from './info'
import ClientEdit from './edit'

const Client = ({
  classes,
  messages,
  isEdit,
}) => (
  <div className={classes.main}>
    <div>
      <div className={classes.bold}>{messages.client}</div>
      {isEdit && <ClientEdit />}
    </div>
    <ClientInfo />
  </div>
)

Client.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
}

Client.defaultProps = {
  isEdit: false,
}

export default component({ styles })(Client)
