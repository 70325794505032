import './invoice.css'

export default {
  main: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 'calc(29.7cm - 6em)',
    maxHeight: 'calc(29.7cm - 6em)',
    width: 'calc(21cm - 4em)',
    maxWidth: 'calc(21cm - 4em)',
    padding: '2em',
    backgroundColor: 'white',

    '& *': {
      fontFamily: 'Quicksand, sans-serif',
    },

    '@media print': {
      boxShadow: 'none',
      margin: 0,
    },

    '@media screen': {
      boxShadow: '0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)',
      borderRadius: '4px',
      margin: '4em',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  dates: {
    marginTop: '2em',
  },

  footer: {
    marginTop: 'auto',
  },
}
