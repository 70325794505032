import React from 'react'
import PropTypes from 'prop-types'
import { component, Button, List } from '@k-mille/ui-components'
import styles from './actions.styles'

const InvoiceActions = ({
  classes,
  id,
  file,
  isPaid,
}) => (
  <List.Item.Actions
    className={classes.main}
  >

    {!isPaid && (
      <Button
        icon="done"
        className={classes.paid}
        id={id}
        name="invoice_paid"
        disabled={isPaid}
      />
    )}
    {file && (
      <Button
        className={classes.pdf}
        id={id}
        icon="pdf"
        name="invoice_download_pdf"
        href={file}
      />
    )}
  </List.Item.Actions>
)

InvoiceActions.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  file: PropTypes.string,
  isPaid: PropTypes.bool,
}

InvoiceActions.defaultProps = {
  file: undefined,
  isPaid: false,
}

export default component({ styles })(InvoiceActions)
