import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { component, Button } from '@k-mille/ui-components'
import styles from './services.styles'
import PriceRow from './priceRow'
import Line from './line'

const Services = ({
  classes,
  messages,
  lines,
  prices,
  isEdit,
  project,
}) => (
  <div className={classes.main}>
    {isEdit && (
      <Button name="ADD_LINE">
        {messages.addLine}
      </Button>
    )}
    <table>
      <tbody>
        {/* header of table */}
        <tr className={classes.header}>

          {isEdit && (<th className={classes.remove}>{/* TODO: select all lines */}</th>)}
          <th className={classes.title}>
            {messages.title}
          </th>
          <th className={classes.number}>
            {messages.number}
          </th>
          <th className={classes.price}>
            {messages.unitPrice}
          </th>
          <th className={classes.price}>
            {messages.total}
          </th>
        </tr>

        {/* all services */}
        {/* eslint-disable-next-line react/no-array-index-key */}
        {lines.map((line, index) => <Line key={index} {...line} />)}

        {/* total price */}
        <PriceRow title={messages.totalWithoutTaxes} value={prices.totalWithoutTaxes} />
        {(project.isAssociation || project.withoutVat) || <PriceRow title={messages.VATRate} type="percent" value={prices.taxesRatio} />}
        {(project.isAssociation || project.withoutVat) || <PriceRow title={messages.VAT} value={prices.taxes} />}
        <PriceRow ttc title={messages.totalWithVAT} value={prices.total} />
      </tbody>
    </table>

    <div className={classes.note}>
      {project.isAssociation || (
        <Fragment>
          {messages.thanksToPay}
          <span className={classes.bold}>
            {messages.toPay}
            {project.withoutVat || messages.withVAT}
          </span>
          {messages.withinWorkingDays}
        </Fragment>
      )}

      {project.withoutVat && <br />}

      {(project.isAssociation || project.withoutVat) && messages.VATNotApplicable}
    </div>
  </div>
)

Services.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  lines: PropTypes.arrayOf(PropTypes.object),
  prices: PropTypes.shape({
    total: PropTypes.number,
    totalWithoutTaxes: PropTypes.number,
    taxes: PropTypes.number,
    taxesRatio: PropTypes.number,
  }),
  project: PropTypes.shape({
    isAssociation: PropTypes.bool,
    withoutVat: PropTypes.bool,
  }),
  isEdit: PropTypes.bool,
}

Services.defaultProps = {
  lines: [],
  prices: {
    total: 0,
  },
  project: {},
  isEdit: false,
}

export default component({ styles })(Services)
