import { invoices, paidInvoice } from './invoices.operations'

export const load = (action, store, { graphql }) => {
  graphql.query(invoices())
}

export const setInvoices = ({ payload }, store) => {
  // calcul since date with print date.
  const formatInvoice = invoice => ({
    ...invoice,
    title: String(invoice.number).padStart(5, '0'),
    dates: {
      ...invoice.dates,
    },
  })

  // show newer on first
  const sortByPrintDate = (curr, next) => next.number - curr.number

  store.data.invoices.set(
    payload
      .map(formatInvoice)
      .sort(sortByPrintDate),
  )
}

export const paid = ({ payload }, store, { graphql }) => {
  const { id } = payload
  graphql.mutation(paidInvoice(), { id })
}

export const refreshPaid = ({ payload }, store) => {
  store.data.invoices.update(payload)
}
