export default {
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '4em',
    fontWeight: 'bold',
    fontFamily: '"Roboto", Helvetica, sans-serif !important',
  },
  number: {
    fontSize: '1.6em',
    fontWeight: 'lighter',
  },
}
